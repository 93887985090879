@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
html body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: #ebeef7;
  margin: 0;
  font-family: Alata, sans-serif;
}

.mat-table {
  font-family: Roboto, sans-serif;
}

.mat-button,
.mat-simple-snackbar,
.mat-form-field,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: Roboto, sans-serif;
}

.prosperas-button {
  background: #1990CF;
}
.prosperas-button:hover {
  background: #005E9D;
}

.success-snackbar {
  background: #bdffdd;
  color: black;
}

.warning-snackbar {
  background: #fff8c9;
  color: black;
}

.error-snackbar {
  background: #fea5bc;
  color: black;
}

.page-title {
  @apply w-full text-3xl text-white ml-6;
}

.input-label {
  @apply w-full bg-white rounded-lg text-lg p-4 mb-4;
}

.summary-number {
  @apply text-3xl;
}
.summary-number-label {
  @apply text-sm;
}

.rounded-dropdown {
  @apply block appearance-none bg-transparent border border-gray-200 text-white py-3 px-4 pr-4 mr-1 rounded-xl leading-tight focus:outline-none focus:bg-transparent focus:border-gray-500;
}

.rounded-dropdown-dark {
  @apply block appearance-none bg-white border border-black text-black py-3 px-4 pr-4 mr-1 rounded-xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500;
}

.rounded-dropdown-white {
  @apply block appearance-none border border-gray-200 text-black py-3 px-4 pr-4 mr-1 rounded-xl leading-tight focus:outline-none focus:bg-transparent focus:border-gray-500;
}

.dropdown-item {
  @apply text-black bg-white;
}

.mat-slider-wrapper {

  .mat-slider-thumb-container {
    .mat-slider-thumb-label {
      width: 50px;
      height: 50px;
      top: -62px;
      right: -25px;
    }
  }
}

.mat-accent .mat-slider-track-fill {
  background-color: #1990CF !important;
}

.mat-slider-thumb-label {
  background-color: #1990CF !important;

}

.mat-slider-thumb {
  background-color: #1990CF !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #1990CF;
  }

.mat-header-cell {
  font-size: 14px;
}

.mat-paginator-outer-container {
  padding-bottom:150px !important;
}

.mat-select-panel {
  transform: translate(10px, 30px) !important
}